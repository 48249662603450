<template>
  <div class="align_div ContactBanner_banner">
  <div class="ContactBanner_container ">
    

    <div class="ContactBanner_container_background_outline">
        <div class="ContactBanner_container_background_shadow"></div>
      <div class="ContactBanner__text">
        <h2>Verder praten?</h2>
        <p>
          Ik sta er altijd voor open voor een babbel. We kunnen het hebben over
          design, development of jouw favoriete muziek.
        </p>
      </div>

    <div class="absolute">
      <div class="ContactBanner_container_outerTriangle">
        <div class="ContactBanner_container_innerTriangle"></div>
        <div class="ContactBanner_container_Triangle_shadow"></div>
      </div>
    </div>
    </div>
    <div class="ContactBanner_bottomBar_container">
        <div class="ContactBanner_container_background_shadow_buttonRow"></div>
      <a href="https://wa.me/31613733474" target="_blank" rel="noopener noreferrer" class="ContactBanner_bottomBar _Icon_left">
          <img class="_icon" src="../assets/WAIcon.png" />
      </a>
      <a href="https://www.linkedin.com/in/midas-maas-82142b5b/" target="_blank" rel="noopener noreferrer"  class="ContactBanner_bottomBar">
          <img class="_icon" src="../assets/LIIcon.png" />
      </a>
      <a href="mailto:midasmaas@gmail.com" target="_blank" rel="noopener noreferrer"  class="ContactBanner_bottomBar">
          <img class="_icon" src="../assets/EMailIcon.png" />
      </a>
      <a href="https://open.spotify.com/playlist/2vsslP12Y9LOqOA91YVHFA?si=8f1d55d8a9de4222" target="_blank" rel="noopener noreferrer"  class="ContactBanner_bottomBar _right">
          <img class="_icon" src="../assets/spotify-128.png" />
      </a>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: "ContactBanner",
};
</script>
