<template>
  <div class="container">
    <h1>TESTVIEW</h1>
    <a href="#">TEST</a>
  </div>
</template>

<script>

export default {
  name: "testView",
};
</script>